<template>
  <div >
    <Menu />
    <div ref="printContent">

      <div class="container-fluid bg-light pt-3 mb-3" v-if="ogListing != ''">
        <div class="row">
          <div class="col-md-12 text-center text-uppercase">
            <p v-if="ogListing[0].status != 'Active'"><strong>This property is no longer available</strong></p>
          </div>
        </div>
      </div>

      <!-- 4219731 -->

      <h1>For Rent</h1>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center" v-if="listing.data.listing[0].id == 4203986">
            <a target=_blank href="/files/the-oceans-edge.pdf" class="btn btn-primary filter-btn" ><i class="bi bi-file-pdf-fill"></i> PDF VERSION</a>
          </div>
          <div class="col-12 text-center" v-else-if="listing.data.listing[0].id == 4219731">
            <a target=_blank href="/files/luxury-meets-nature.pdf" class="btn btn-primary filter-btn" ><i class="bi bi-file-pdf-fill"></i> PDF VERSION</a>
          </div>
          <div class="col-12 text-center" v-else>
            <a target=_blank :href="'/brochure/' + listing.data.listing[0].id" class="btn btn-primary filter-btn" ><i class="bi bi-file-pdf-fill"></i> PDF VERSION</a>
          </div>
        </div>
      </div>

      <div style="z-index: 999; position: fixed; bottom: 0px; right: 0px; padding: 15px; background-color: #efefef; border-top-left-radius: 30px;" class="text-end">
        <a v-on:click="incrementLeads" id="whatsapp-agent-property" v-if="this.agent.first_name != null" target="_blank" style="font-size: 28px; text-decoration: none; color: #e00233 " :href="whatsappLink"><strong> <span style="font-size: 10px; line-height: 10px;">{{ agent.first_name }} &nbsp;</span><i class="bi bi-whatsapp"></i></strong></a><br/>
        <a v-on:click="incrementLeads" id="whatsapp-agent-property-2" v-if="this.agent_2.first_name != null" target="_blank" style="font-size: 28px; text-decoration: none; color: #e00233 " :href="whatsappLink_2"><strong> <span style="font-size: 10px;">{{ agent_2.first_name }} &nbsp;</span><i class="bi bi-whatsapp"></i></strong></a><br/>
        <a v-on:click="incrementLeads" id="whatsapp-agent-property-3" v-if="this.agent_3.first_name != null" target="_blank" style="font-size: 28px; text-decoration: none; color: #e00233 " :href="whatsappLink_3"><strong> <span style="font-size: 10px; line-height: 10px;">{{ agent_3.first_name }} &nbsp;</span><i class="bi bi-whatsapp"></i></strong></a>
      </div>

      <div class="fixed-tags bg-light" style="width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;">
        <div class="container mt-2 mb-2">
          <div class="row">
            <div class="col-12 text-end">
              <span style="font-size: 20px; color: #000;"><i class="bi bi-share-fill"></i></span><br/>
              <a id="social-share-property-fb" style="font-size: 20px;" :href="facebookShareLink" target="_blank">
                <i class="bi bi-facebook switch-blue"></i>
              </a><br/>
              <a id="social-share-property-pin" style="font-size: 20px;" :href="pinterestShareLink" target="_blank">
                <i class="bi bi-pinterest switch-blue"></i>
              </a><br/>
            </div>
          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body px-3">
          <ContactForm />
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12 text-start">
            <p v-if="this.listing.data.listing[0].on_show && !loading"><strong>ON SHOW</strong> <span v-if="this.listing.data.listing[0].on_show_every_saturday">Saturdays</span> <span v-if="this.listing.data.listing[0].on_show_every_sunday">Sundays</span> <span> {{ listing.data.listing[0].on_show_start_time }}</span></p>
          </div>
        </div>
      </div>

      <section v-if="listing.template == 1 && !loading">
        
        <div id="banner" class="my-5">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-12">
                <div class="porfolio-banner">
                  <img :src="listing.featured_image" class="w-100" style="border-top-left-radius: 30px; border-bottom-right-radius: 30px;" />
                  <h1 class="text-md-start text-center p-2" style="background: rgba(255, 255, 255, 0.8); border-bottom-right-radius: 30px;">{{ listing.data.listing[0].marketing_heading }}<br/><br/>
                    <span>{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                    <strong v-if="listing.data.listing[0].poa !== true">R {{ formattedPrice }}</strong>
                    <strong v-if="listing.data.listing[0].poa === true">POA</strong>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="switch-bg-lblue pt-5">
          <div class="wrapper position-relative">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="switch-blue pt-3">Overview</h5>
                    <p>{{ listing.ai_description }}</p>
                    <hr>
                </div>
                <div class="col-md-12 align-self-center">
                  <div class="overview-img-block overview-img-block1">
                    <div class="portfolio-type position-relative">
                      <div class="d-inline-block" v-if="listing.data.listing[0].land_size !== 0">
                        <div class="row g-0">
                          <div class="col p-0">{{ listing.data.listing[0].land_size }} M2 LAND SIZE</div>
                        </div>
                      </div>
                      <div class="d-inline-block">
                        <div class="row g-0">
                          <div class="col p-0">{{ listing.data.listing[0].lounges }} LOUNGES</div>
                        </div>
                      </div>
                      <div class="d-inline-block">
                        <div class="row g-0">
                          <div class="col p-0">{{ listing.data.listing[0].bedrooms }} BEDS</div>
                        </div>
                      </div>
                      <div class="d-inline-block">
                        <div class="row g-0">
                          <div class="col p-0">{{ listing.data.listing[0].bathrooms }} BATHS</div>
                        </div>
                      </div>
                    </div>
                    <img :src="ogListing[0].listing_images[1]" class="mw-100 rounded" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid bg-light">
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h1>CONTACT DETAILS</h1>
              <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
              </button>
            </div>
          </div>
          
          <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                    <div v-if="!contactRequested">
                      <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                      <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                      <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                    <div v-if="!contactRequested_2">
                      <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_2">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                      <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                    <div v-if="!contactRequested_3">
                      <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_3">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                      <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper">
          <div class="container mt-5">
            <div class="row mb-5">
              <div :class="{'col-md-12 mt-3': !listing.data.listing[0].video_id, 'col-md-8 mt-3': listing.data.listing[0].video_id}">
                  <h5 class="switch-blue">About this property</h5>
                  <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
              </div>
              <div class="col-md-4 mt-5" v-if="listing.data.listing[0].video_id !== null">
                  <iframe
                    width="100%"
                    height="315"
                    v-if="listing.data.listing[0].video_id !== null"
                    :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
              </div>
            </div>
            <div class="row">
              <div id="carouselExampleIndicators" class="carousel slide">
                <div class="carousel-inner">
                  <div v-for="(item, index) in ogListing[0].listing_images">
                    <div class="carousel-item" :class="{ active: index == 2 }">
                      <div class="row justify-content-center">
                        <div class="col-sm-11">
                          <img
                            :src="item"
                            class="w-100"
                            @click="expandImage(index)"
                            :class="{ 'expanded': isImageExpanded && activeImageIndex === index }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Amenities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Activities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Schools</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active switch-bg-lpblue p-lg-5 p-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Amenities
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Amenities</h5>
                            <p>{{ listing.ai_amenities_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Activities
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo" data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Activities</h5>
                            <p>{{ listing.ai_activities_description }}</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Schools
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree" data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Schools</h5>
                            <p>{{ listing.ai_schools_description }}</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5 pt-4">
              <div :class="{'col-md-12 mt-3': !listing.data.listing[0].map_x_position, 'col-md-6 mt-3': listing.data.listing[0].map_x_position}">
                <h5 class="switch-blue">About this location</h5>
                <p>{{ listing.ai_location_description }}</p>
              </div>
              <div class="col-md-6 amenities2"  v-if="listing.data.listing[0].map_x_position != null">
                <iframe
                  v-if="listing.data.listing[0].map_x_position != null"
                  width="100%"
                  height="450"
                  frameborder="0"
                  style="border:0"
                  :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="listing.template == 2 && !loading">
        <div id="banner" class="my-5">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-12">
                <div class="porfolio-banner">
                  <img :src="listing.featured_image" class="w-100" style="border-top-right-radius: 50px;" />
                  <h1 class="text-md-start text-center p-2" style="background: rgba(255, 255, 255, 0.5)">{{ listing.data.listing[0].marketing_heading }}
                    <span>{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                    <span v-if="listing.data.listing[0].poa === true"><strong>POA</strong></span>
                    <span v-if="listing.data.listing[0].poa !== true"><strong>R {{ formattedPrice  }}</strong></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid bg-light">
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h1>CONTACT DETAILS</h1>
              <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
              </button>
            </div>
          </div>
          
          <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                    <div v-if="!contactRequested">
                      <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                      <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                      <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                    <div v-if="!contactRequested_2">
                      <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_2">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                      <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                    <div v-if="!contactRequested_3">
                      <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_3">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                      <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="switch-bg-dblue">
          <div class="wrapper">
            <div class="container position-relative py-5">
              <div class="row justify-content-center">
                <div class="col-lg-10 align-self-center my-4">
                  <div class="property-overview-block property-overview-block2">
                    <div v-if="listing.data.listing[0].poa !== true" class="price-tag text-end p-tag2">R {{ formattedPrice }}</div>
                    <div v-if="listing.data.listing[0].poa === true" class="price-tag text-end p-tag2">POA</div>
                    <h2 class=" text-start text-uppercase mb-4"><strong class="switch-white">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}, {{ listing.data.listing[0].loc_country }}</strong></h2>
                    <div id="carouselTemplateTwo" class="carousel">
                      <div class="carousel-inner">
                        <div v-for="(item, index) in ogListing[0].listing_images">
                          <div class="carousel-item" :class="{ active: index == 1 }">
                            <img :src="item" class="w-100 bord-radius" />
                          </div>
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselTemplateTwo"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselTemplateTwo"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 position-relative">
              <div class="portfolio-type portfolio-type2">
                <div class="d-inline-block" v-if="listing.data.listing[0].land_size !== 0">
                  <div class="row g-0">
                    <div class="col p-0">{{ listing.data.listing[0].land_size }} M2 LAND SIZE</div>
                  </div>
                </div>
                <div class="d-inline-block">
                  <div class="row g-0">
                    <div class="col p-0">{{ listing.data.listing[0].lounges }} LOUNGES</div>
                  </div>
                </div>
                <div class="d-inline-block">
                  <div class="row g-0">
                    <div class="col p-0">{{ listing.data.listing[0].bedrooms }} BEDS</div>
                  </div>
                </div>
                <div class="d-inline-block">
                  <div class="row g-0">
                    <div class="col p-0">{{ listing.data.listing[0].bathrooms }} BATHS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="wrapper pt-5">
          <div class="container mt-5">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h5 class="switch-blue">Overview</h5>
                <p>{{ listing.ai_description }}</p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-6">
                <h5 class="switch-blue mb-4">About this property</h5>
                <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
              </div>
              <div class="col-lg-6 amenities2 mt-5">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#amenities-tab-temp3"
                      type="button" role="tab" aria-controls="amenities-tab-temp3" aria-selected="true">Amenities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#main-tab-temp3"
                      type="button" role="tab" aria-controls="main-tab-temp3" aria-selected="false">Activities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#outdoors-tab-temp3"
                      type="button" role="tab" aria-controls="outdoors-tab-temp3" aria-selected="false">Schools</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active switch-bg-lpblue p-lg-5 p-3" id="amenities-tab-temp3"
                    role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Amenities
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingOne"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Amenities</h5>
                            <p>{{ listing.ai_amenities_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="main-tab-temp3" role="tabpanel"
                    aria-labelledby="profile-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingTw0">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Activities
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingTw0"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Activities</h5>
                            <p>{{ listing.ai_activities_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="outdoors-tab-temp3" role="tabpanel"
                    aria-labelledby="contact-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Schools
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Schools</h5>
                            <p>{{ listing.ai_schools_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5" v-if="listing.data.listing[0].video_id">
                    <iframe
                      width="100%"
                      height="315"
                      :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5">
              <div class="col-lg-6">
                <h5 class="switch-blue">ABOUT {{ listing.data.listing[0].loc_suburb }}</h5>
                <p>{{ this.listing.ai_location_description }}</p>
              </div>
              <div class="col-lg-6 amenities2">
                <iframe
                  width="100%"
                  height="450"
                  frameborder="0"
                  style="border:0"
                  v-if="listing.data.listing[0].map_x_position != null"
                  :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="listing.template == 3 && !loading">
        <div id="banner" class="mb-5 mt-5">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-lg-4 switch-bg-lpblue ps-4 order-lg-1 order-2 position-relative bg-light">
                <div class="banner-section temp3">
                  <h1 class="text-start">{{ listing.data.listing[0].marketing_heading }}
                    <span>{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                  </h1>
                </div>
                <div class="row justify-content-lg-end push50T">
                  <div class="col-lg-6  position-relative">
                    <div class="portfolio-type portfolio-type3">
                      <div class="type3"  v-if="listing.data.listing[0].land_size !== 0">
                        <div class="row g-0">
                          <div class="col-1"><img src="/images/icons/size-icon.png" class="mw-100" width="20px"></div>
                          <div class="col p-0">{{ listing.data.listing[0].land_size }} M2 LAND SIZE</div>
                        </div>
                      </div>
                      <div class="type3">
                        <div class="row g-0">
                          <div class="col-1"><img src="/images/icons/room-icon.png" class="mw-100" width="15px"></div>
                          <div class="col p-0">{{ listing.data.listing[0].lounges }} LOUNGES</div>
                        </div>
                      </div>
                      <div class="type3">
                        <div class="row g-0">
                          <div class="col-1"><img src="/images/icons/beds-icon.png" class="mw-100" width="20px"></div>
                          <div class="col p-0">{{ listing.data.listing[0].bedrooms }} BEDS</div>
                        </div>
                      </div>
                      <div class="type3">
                        <div class="row g-0">
                          <div class="col-1"><img src="/images/icons/baths-icon.png" class="mw-100" width="20px"></div>
                          <div class="col p-0">{{ listing.data.listing[0].bathrooms }} BATHS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="social-block">
                  <div class="row">
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-8 order-lg-2 order-1">
                <div class="porfolio-banner">
                  <img :src="listing.featured_image" class="w-100" style="border-top-right-radius: 30px;" />
                  <div v-if="listing.data.listing[0].poa !== true" class="price-tag text-end p-tag2" style="border-top-right-radius: 30px; border-top-left-radius: 30px; bottom: 0px;">R {{ formattedPrice }}</div>
                  <div v-if="listing.data.listing[0].poa === true" class="price-tag text-end p-tag2" style="border-top-right-radius: 30px; border-top-left-radius: 30px; bottom: 0px;">POA</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid bg-light">
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h1>CONTACT DETAILS</h1>
              <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
              </button>
            </div>
          </div>
          
          <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                    <div v-if="!contactRequested">
                      <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                      <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                      <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                    <div v-if="!contactRequested_2">
                      <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_2">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                      <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                    <div v-if="!contactRequested_3">
                      <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_3">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                      <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="wrapper">
            <div class="container">
              <div class="row mb-3">
                <div :class="{'col-md-12 mt-3': !listing.data.listing[0].video_id, 'col-md-7 mt-3': listing.data.listing[0].video_id}">
                  <h3 class="switch-blue">Overview</h3>
                  <p>{{ listing.ai_description }}</p>
                </div>
                <div class="col-md-5" v-if="listing.data.listing[0].video_id !== null || listing.data.listing[0].video_id != ''">
                    <iframe
                      width="100%"
                      height="315"
                      v-if="listing.data.listing[0].video_id !== null"
                      :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div class="switch-bg-lgrey">
            <div class="container position-relative py-md-5 pb-4">
              <div class="row justify-content-center">
                <div class="col-12 align-self-center my-4">
                  <div class="property-overview-block property-overview-block3" id="carousel-toggle">
                    <div id="carouselTemplateThree" class="carousel slide temp2">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="row g-0">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[0]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[1]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[2]" class="w-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="row g-0">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[3]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[4]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[5]" class="w-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="row g-0">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[6]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[7]" class="w-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[8]" class="w-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="0" class="active"
                          aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="1"
                          aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="2"
                          aria-label="Slide 3"></button>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselTemplateThree"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselTemplateThree"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="container mt-5">
            <div class="row mt-5">
              <div class="col-12">
                <h5 class="switch-blue mb-4">About this property</h5>
                <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
              </div>
              <div class="col-12 amenities2 mt-5">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#amenities-tab-temp3"
                      type="button" role="tab" aria-controls="amenities-tab-temp3" aria-selected="true">Amenities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#main-tab-temp3"
                      type="button" role="tab" aria-controls="main-tab-temp3" aria-selected="false">Activities</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#outdoors-tab-temp3"
                      type="button" role="tab" aria-controls="outdoors-tab-temp3" aria-selected="false">Schools</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active switch-bg-lpblue p-lg-5 p-3" id="amenities-tab-temp3"
                    role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Amenities
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingOne"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Amenities</h5>
                            <p>{{ listing.ai_amenities_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="main-tab-temp3" role="tabpanel"
                    aria-labelledby="profile-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingTw0">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Activities
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingTw0"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Activities</h5>
                            <p>{{ listing.ai_activities_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade switch-bg-lpblue p-lg-5 p-3" id="outdoors-tab-temp3" role="tabpanel"
                    aria-labelledby="contact-tab" tabindex="0">
                    <h2 class="accordion-header d-lg-none" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Schools
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree"
                      data-bs-parent="#myTabContent">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="switch-ddblue mb-4">Schools</h5>
                            <p>{{ listing.ai_schools_description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 pt-4">
              <div :class="{'col-md-12 mt-3': !listing.data.listing[0].map_x_position, 'col-md-6 mt-3': listing.data.listing[0].map_x_position}">
                <h5 class="switch-blue">ABOUT {{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</h5>
                <p>{{ listing.ai_location_description }}</p>
              </div>
              <div class="col-lg-6 amenities2"  v-if="listing.data.listing[0].map_x_position != null">
                <iframe
                  width="100%"
                  height="450"
                  frameborder="0"
                  style="border:0"
                  v-if="listing.data.listing[0].map_x_position != null"
                  :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="listing.template == 4 && !loading">
        <div id="banner" class="mt-5">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-12">
                <div class="banner-section porfolio-banner porfolio-banner4">
                  <img :src="listing.featured_image" class="w-100" />
                  <div class="switch-bg-dblue name-property-details px-3 py-2">
                    <div class="row g-0">
                      <div class="col-lg-6 col-md-5">
                        <div class="">
                          <h4 class="text-sm-start text-center switch-white">{{ listing.data.listing[0].marketing_heading }}
                            <span class="mt-2">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                          </h4>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-7 align-self-center">
                        <div class="portfolio-type portfolio-type4 float-md-end">
                          <div class="type4" v-if="listing.data.listing[0].land_size !== 0">
                            <p>{{ listing.data.listing[0].land_size }}<br/>M2 LAND SIZE</p>
                          </div>
                          <div class="type4">
                            <p>{{ listing.data.listing[0].lounges }} <br/>LOUNGES</p>
                          </div>
                          <div class="type4">
                            <p>{{ listing.data.listing[0].bedrooms }} <br/>BEDS</p>
                          </div>
                          <div class="type4">
                            <p>{{ listing.data.listing[0].bathrooms }} <br/>BATHS</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="listing.data.listing[0].poa !== true" class="price-tag text-end p-tag2">R {{ formattedPrice }}</div>
                  <div v-if="listing.data.listing[0].poa === true" class="price-tag text-end p-tag2">POA</div>

                  <div class="row g-0 justify-content-center">
                    <div class="col-lg-12 col-md-12 col-sm-10 order-md-1 order-2">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid bg-light">
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h1>CONTACT DETAILS</h1>
              <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
              </button>
            </div>
          </div>
          
          <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                    <div v-if="!contactRequested">
                      <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                      <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                      <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                    <div v-if="!contactRequested_2">
                      <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_2">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                      <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                    <div v-if="!contactRequested_3">
                      <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_3">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                      <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="wrapper">
            <div class="container">
              <div class="row">
                <div class="col-12 text-center mb-5 mt-5">
                  <h5 class="switch-blue">Overview</h5>
                  <p>{{ listing.ai_description }}</p>
                </div>
                <div class="col-lg-6 amenities2">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                        data-bs-target="#amenities-tab-temp4" type="button" role="tab" aria-controls="amenities-tab-temp4"
                        aria-selected="true">Amenities</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#main-tab-temp4"
                        type="button" role="tab" aria-controls="main-tab-temp4" aria-selected="false">Activities</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#outdoors-tab-temp4"
                        type="button" role="tab" aria-controls="outdoors-tab-temp4" aria-selected="false">Schools</button>
                    </li>
                  </ul>

                  <div class="tab-content tabs-temp4" id="myTabContent">
                    <div class="tab-pane fade show active switch-bg-dblue p-lg-5 p-3 h-100" id="amenities-tab-temp4"
                      role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Amenities
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingOne"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12">
                              <h5 class="switch-ddblue mb-4">Amenities</h5>
                              <p>{{ listing.ai_amenities_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade p-lg-5 p-3 switch-bg-dblue h-100" id="main-tab-temp4" role="tabpanel"
                      aria-labelledby="profile-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Activities
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12">
                              <h5 class="switch-ddblue mb-4">Activities</h5>
                              <p>{{ listing.ai_activities_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="tab-pane fade p-lg-5 p-3 switch-bg-dblue h-100" id="outdoors-tab-temp4" role="tabpanel"
                      aria-labelledby="contact-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Schools
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12">
                              <h5 class="switch-ddblue mb-4">Schools</h5>
                              <p>{{ listing.ai_schools_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 align-self-center pt-5">
                  <div class="row">
                    <div>
                      <div id="carouselExampleIndicators" class="carousel slide">
                        <div class="carousel-inner">
                          <div v-for="(item, index) in ogListing[0].listing_images">
                            <div class="carousel-item" :class="{ active: index == 2 }">
                              <div class="row justify-content-center">
                                <div class="col-sm-11">
                                  <img
                                    :src="item"
                                    class="w-100"
                                    @click="expandImage(index)"
                                    :class="{ 'expanded': isImageExpanded && activeImageIndex === index }"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper pt-5">
            <div class="container mt-5">
              <div class="row mt-5">
                <div class="col-12 text-start">
                  <h5 class="switch-blue mb-4">About this property</h5>
                  <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
                  <iframe
                    width="100%"
                    height="315"
                    v-if="listing.data.listing[0].video_id !== null"
                    :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                  <h5 class="switch-blue mt-4 mb-4">ABOUT {{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</h5>
                  <p>{{ listing.ai_location_description }}</p>
                  <div  v-if="listing.data.listing[0].map_x_position != null">
                  <iframe 
                    width="100%"
                    height="450"
                    frameborder="0"
                    style="border:0"
                    v-if="listing.data.listing[0].map_x_position != null"
                    :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                    allowfullscreen
                  ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="listing.template == 5 && !loading">
        <div id="banner" class="my-5">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-12">
                <div class="banner-section porfolio-banner porfolio-banner5 ">
                  <div id="carouselTemplateFive" class="carousel slide">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img :src="listing.featured_image" class="w-100 no-border" />
                        <div class="row">
                          <div class="col-md-7">
                            <div class="port-type5">
                              <h4 class="text-start switch-white">{{ listing.data.listing[0].marketing_heading }}
                                <span class="mt-2">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                                <span v-if="listing.data.listing[0].poa !== true" class="mt-2"><strong style="color: white; font-size: 18px;">R {{ formattedPrice }}</strong></span>
                                <span v-if="listing.data.listing[0].poa === true" class="mt-2"><strong style="color: white; font-size: 18px;">POA</strong></span>
                              </h4>
                              <p class="switch-white">{{ listing.ai_description }}</p>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="0"
                                aria-current="true" aria-label="Slide 1" class="active">
                                <img :src="listing.featured_image" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="1"
                                aria-label="Slide 2">
                                <img :src="ogListing[0].listing_images[1]" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="2"
                                aria-label="Slide 3">
                                <img :src="ogListing[0].listing_images[2]" class="mw-100" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img :src="ogListing[0].listing_images[1]" class="w-100 no-borderR" />
                        <div class="row">
                          <div class="col-md-7">
                            <div class="port-type5">
                              <h4 class="text-start switch-white">{{ listing.data.listing[0].marketing_heading }}
                                <span class="mt-2">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                              </h4>
                              <p class="switch-white">{{ listing.ai_description }}</p>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="0"
                                aria-current="true" aria-label="Slide 1">
                                <img :src="ogListing[0].listing_images[0]" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="1"
                                aria-label="Slide 2" class="active">
                                <img :src="ogListing[0].listing_images[1]" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="2"
                                aria-label="Slide 3">
                                <img :src="ogListing[0].listing_images[2]" class="mw-100" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img :src="ogListing[0].listing_images[2]" class="w-100 no-borderR" />
                        <div class="row">
                          <div class="col-md-7">
                            <div class="port-type5">
                              <h4 class="text-start switch-white">{{ listing.data.listing[0].marketing_heading }}
                                <span class="mt-2">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span>
                              </h4>
                              <p class="switch-white">{{ listing.ai_description }}</p>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="0"
                                aria-current="true" aria-label="Slide 1">
                                <img :src="ogListing[0].listing_images[0]" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="1"
                                aria-label="Slide 2">
                                <img :src="ogListing[0].listing_images[1]" class="mw-100" />
                              </button>
                              <button type="button" data-bs-target="#carouselTemplateFive" data-bs-slide-to="2"
                                aria-label="Slide 3" class="active">
                                <img :src="ogListing[0].listing_images[2]" class="mw-100" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button class="carousel-control-prev carousel-btn" type="button" data-bs-target="#carouselTemplateFive"
                      data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next carousel-btn" type="button" data-bs-target="#carouselTemplateFive"
                      data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div class="switch-bg-dblue name-property-details px-3 py-2">
                    <div class="row justify-content-between">
                      <div class="col-lg-8 col-md-7 align-self-center">
                        <div class="portfolio-type portfolio-type4 portfolio-type5">
                          <div class="type4 type5" v-if="listing.data.listing[0].land_size !== 0">
                            <img src="/images/icons/size-icon.png" class="mw-100 d-block" width="23px">
                            <p>{{ listing.data.listing[0].land_size }} M2 LAND SIZE</p>
                          </div>
                          <div class="type4 type5">
                            <img src="/images/icons/room-icon.png" class="mw-100 d-block" width="15px">
                            <p>{{ listing.data.listing[0].lounges }} LOUNGES</p>
                          </div>
                          <div class="type4 type5">
                            <img src="/images/icons/beds-icon.png" class="mw-100 d-block" width="24px">
                            <p>{{ listing.data.listing[0].bedrooms }} BEDROOMS</p>
                          </div>
                          <div class="type4 type5">
                            <img src="/images/icons/baths-icon.png" class="mw-100 d-block" width="31px">
                            <p>{{ listing.data.listing[0].bathrooms }} BATHS</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-5 align-self-center">
                        <div class="portfolio-type portfolio-type4 ">
                          <div class="type4 type5 switch-bg-white position-relative">
                            <p class="my-0" v-if="listing.data.listing[0].poa !== true"><strong>R {{ formattedPrice }}</strong></p>
                            <p class="my-0" v-if="listing.data.listing[0].poa === true"><strong>POA</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid bg-light">
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h1>CONTACT DETAILS</h1>
              <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
              </button>
            </div>
          </div>
          
          <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                    <div v-if="!contactRequested">
                      <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                      <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                      <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                    <div v-if="!contactRequested_2">
                      <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_2">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                      <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
              <div class="container">
                <div class="row pt-4">
                  <div class="col-md-5">
                    <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                    <div v-if="!contactRequested_3">
                      <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                    </div>
                    <div v-if="contactRequested_3">
                      <p style="line-height: 26px;">
                      <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                      <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                      <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="wrapper">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <h5 class="switch-blue mb-4">About this property</h5>
                  <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
                  <iframe
                    width="100%"
                    height="315"
                    v-if="listing.data.listing[0].video_id !== null"
                    :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                  <div class="slider-block mt-5">
                    <img :src="ogListing[0].listing_images[4]" class="w-100" />
                    <div id="carouselExample" class="carousel slide">
                      <div class="carousel-inner">
                        <div class="carousel-item active switch-bg-dblue p-4 px-5">
                          <h5 class=" mb-4">AMENITIES</h5>
                          <p>{{ listing.ai_amenities_description }}</p>
                        </div>
                        <div class="carousel-item switch-bg-dblue p-4 px-5">
                          <h5 class=" mb-4">ACTIVITIES</h5>
                          <p>{{ listing.ai_activities_description }}</p>
                        </div>
                        <div class="carousel-item switch-bg-dblue p-4 px-5">
                          <h5 class=" mb-4">SCHOOLS</h5>
                          <p>{{ listing.ai_schools_description }}</p>
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="container">
              <div class="row mt-5">
                <div class="col-12">
                  <h5 class="switch-blue mt-5">ABOUT</h5>
                  <p>{{ listing.ai_location_description }}</p>
                  <div v-if="listing.data.listing[0].map_x_position != null">
                  <iframe
                  width="100%"
                  height="450"
                  frameborder="0"
                  style="border:0"
                  v-if="listing.data.listing[0].map_x_position != null"
                  :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                  allowfullscreen
                  ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="listing.template == 6 && !loading">
        <div id="banner" class="mt-5 mb-3">
          <div class="container">
            <div class="row justify-content-center g-0">
              <div class="col-12">
                <div class="banner-section porfolio-banner porfolio-banner6">
                  <div class="row g-0">
                    <div class="col-lg-4 col-md-5 align-self-center order-1 pe-2">
                      <h4>{{ listing.data.listing[0].marketing_heading }}<span>{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</span></h4>
                      <div class="underLineBorder"></div>
                      <div class="priceTag pb-3" v-if="listing.data.listing[0].poa !== true">R {{ formattedPrice }}</div>
                      <div class="priceTag pb-3" v-if="listing.data.listing[0].poa === true">POA</div>
                    </div>
                    <div class="col-lg-8 col-md-7 col-sm-7 order-md-2 order-sm-4 order-2">
                      <img :src="listing.featured_image" class="mw-100" style="border-top-left-radius: 30px;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <img :src="ogListing[0].listing_images[2]" class="mw-100 zoomable-image pb-3" />
            </div>
            <div class="col-md-6">
              <img :src="ogListing[0].listing_images[4]" class="mw-100 zoomable-image pb-3" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <img :src="ogListing[0].listing_images[5]" class="mw-100 zoomable-image pb-3" />
            </div>
            <div class="col-md-6">
              <img :src="ogListing[0].listing_images[6]" class="mw-100 zoomable-image pb-3" />
            </div>
          </div>
        </div>
        <div class="pt-lg-5">
          <div class="wrapper">
            <div class="container">
              <div class="row">
                <div class="col-12  mb-5">
                  <div class="switch-bg-lgrey p-sm-5 p-4 pb-5 about-block">
                    <h5 class="switch-ddblue mb-4">About this property</h5>
                    <p v-for="(paragraph, index) in formattedDescription" :key="index">{{ paragraph }}</p>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center pb-5 mb-5">
                <div class="col-md-3 col-sm-6">
                  <div class="prop-details">{{ listing.data.listing[0].bedrooms }}<span>TOTAL BEDS</span></div>
                </div>
                <div class="col-md-3 col-sm-6" v-if="listing.data.listing[0].land_size !== 0">
                  <div class="prop-details">{{ listing.data.listing[0].land_size }}<span>M2 LAND SIZE</span></div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="prop-details">{{ listing.data.listing[0].lounges }}<span>TOTAL LOUNGES</span></div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="prop-details">{{ listing.data.listing[0].bathrooms }}<span>TOTAL BATHROOMS</span></div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid bg-light">
            <div class="row">
              <div class="col-12 text-center mt-3">
                <h1>CONTACT DETAILS</h1>
                <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <i class="bi bi-envelope"></i> &nbsp; &nbsp; Send a message
                </button>
              </div>
            </div>
            
            <div class="row justify-content-center pt-5 pb-5">
              <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
                <div class="container">
                  <div class="row pt-4">
                    <div class="col-md-5">
                      <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                    </div>
                    <div class="col-md-7 text-center">
                      <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                      <div v-if="!contactRequested">
                        <center><button id="contact-reveal-agent" class="btn btn-primary filter-btn" v-on:click="contactRequested = true; incrementLeads();">Details</button></center>
                      </div>
                      <div v-if="contactRequested">
                        <p style="line-height: 26px;">
                        <a id="call-agent-property" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                        <a id="email-agent-property" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                        <a id="whatsapp-agent-property" style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
                <div class="container">
                  <div class="row pt-4">
                    <div class="col-md-5">
                      <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                    </div>
                    <div class="col-md-7 text-center">
                      <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                      <div v-if="!contactRequested_2">
                        <center><button id="contact-reveal-agent-2" class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true; incrementLeads();">Details</button></center>
                      </div>
                      <div v-if="contactRequested_2">
                        <p style="line-height: 26px;">
                        <a id="call-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                        <a id="email-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                        <a id="whatsapp-agent-property-2" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>\
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
                <div class="container">
                  <div class="row pt-4">
                    <div class="col-md-5">
                      <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
                    </div>
                    <div class="col-md-7 text-center">
                      <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                      <div v-if="!contactRequested_3">
                        <center><button id="contact-reveal-agent-3" class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true; incrementLeads();">Details</button></center>
                      </div>
                      <div v-if="contactRequested_3">
                        <p style="line-height: 26px;">
                        <a id="call-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                        <a id="email-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                        <a id="whatsapp-agent-property-3" style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <div class="container">
              <div class="row mt-5">
                <div class="col-12 align-self-center my-4">
                  <iframe
                    width="100%"
                    height="315"
                    v-if="listing.data.listing[0].video_id !== null"
                    :src="'https://www.youtube.com/embed/' + listing.data.listing[0].video_id + '?rel=0&modestbranding=1'"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                  <div class="property-overview-block property-overview-block3 mt-5" id="carousel-toggle">
                    <div id="carouselTemplateThree" class="carousel slide temp2">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="row">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[8]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[9]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[10]" class="mw-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="row">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[11]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[12]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[13]" class="mw-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="row">
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[0]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[1]" class="mw-100 zoomable-image" /></button></div>
                            <div class="col-12 col-md-4"><button type="button" class="carousel-zoom"><img
                                  :src="ogListing[0].listing_images[2]" class="mw-100 zoomable-image" /></button></div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="0" class="active"
                          aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="1"
                          aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselTemplateThree" data-bs-slide-to="2"
                          aria-label="Slide 3"></button>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselTemplateThree"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselTemplateThree"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-4">
                  <ul class="nav nav-tabs temp6-tabs justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="border-tab" data-bs-toggle="tab" data-bs-target="#border-tab-pane"
                        type="button" role="tab" aria-controls="border-tab-pane" aria-selected="true">Amenities</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="border2-tab" data-bs-toggle="tab" data-bs-target="#border2-tab-pane"
                        type="button" role="tab" aria-controls="border2-tab-pane" aria-selected="false">Activities</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="border3-tab" data-bs-toggle="tab" data-bs-target="#border3-tab-pane"
                        type="button" role="tab" aria-controls="border3-tab-pane" aria-selected="false">Schools</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active p-lg-5 p-3" id="border-tab-pane" role="tabpanel"
                      aria-labelledby="border-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Amenities
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingOne"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12 align-self-center">
                              <h5 class="switch-ddblue mb-4">Amenities</h5>
                              <p>{{ listing.ai_amenities_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade p-lg-5 p-3" id="border2-tab-pane" role="tabpanel"
                      aria-labelledby="border2-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Activities
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12 align-self-center">
                              <h5 class="switch-ddblue mb-4">Activities</h5>
                              <p>{{ listing.ai_activities_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="tab-pane fade p-lg-5 p-3" id="border3-tab-pane" role="tabpanel"
                      aria-labelledby="border3-tab" tabindex="0">
                      <h2 class="accordion-header d-lg-none" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Schools
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse d-lg-block" aria-labelledby="headingThree"
                        data-bs-parent="#myTabContent">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-12 align-self-center">
                              <h5 class="switch-ddblue mb-4">Schools</h5>
                              <p>{{ listing.ai_schools_description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h5 class="switch-blue">ABOUT {{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</h5>
                  <p>{{ listing.ai_location_description }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" v-if="listing.data.listing[0].map_x_position !== null">
                  <iframe
                    width="100%"
                    height="450"
                    frameborder="0"
                    style="border:0"
                    :src="'/map.html?lat=' + listing.data.listing[0].map_x_position + '&lng=' + listing.data.listing[0].map_y_position + '&zoom=12'"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div v-for="item in navigationButtons" v-if="item.currentId == current">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center mt-5">
              <p><strong>Browse our portfolio</strong></p>
            </div>
          </div>
        </div>
        <center>
          <a class="btn btn-primary" v-if="item.lastId != null" :href="'/rental/' + item.lastId"><i class="bi bi-arrow-left"></i> Prev</a> 
          <a class="btn btn-primary" v-if="item.nextId != null" :href="'/rental/' + item.nextId">Next <i class="bi bi-arrow-right"></i></a>
        </center>
      </div>
      
      <!-- <div class="container pb-5 mb-5">
        <div class="row pt-5 justify-content-center">
          <div class="col-md-4 mt-5 mb-4 pb-4" v-for="(item, index) in originalListings" :key="index" v-if="originalListings[index] && originalListings[index].payload.listing.featured_image != null && originalListings[index].payload.listing.data.listing[0].loc_suburb == listing.data.listing[0].loc_suburb && originalListings[index].payload.listing.data.listing[0].id != listing.data.listing[0].id">
            <center>
              <p>Also in {{ originalListings[index].payload.listing.data.listing[0].loc_suburb }}</p>
            </center>
            <div class="banner-section lifestyle-block switch-bg-lgrey p-3 h-100 bord-radius">
              <div class="portfolio-thumbnail">
                <a :href="'/property/' + originalListings[index].payload.listing.data.listing[0].id">
                  <img :src="originalListings[index].payload.listing.featured_image" class="w-100 force-height">
                </a>
                <div class="thumbnail-priceTag">
                  {{ formatCurrency(originalListings[index].payload.listing.data.listing[0].price) }}
                </div>
                <div class="portfolio-type">
                  <div class="typeOne mx-1" v-for="(tag, propertyName) in originalListings[index].payload.listing.tags" v-if="tag == true">
                    {{ propertyName }}
                  </div>
                </div>
              </div>
              <h4 class="text-start">
                {{ originalListings[index].payload.listing.data.listing[0].marketing_heading }}
                <span>
                  {{ originalListings[index].payload.listing.data.listing[0].loc_suburb }},
                  {{ originalListings[index].payload.listing.data.listing[0].loc_region }}
                </span>
              </h4>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" :href="'/property/' + originalListings[index].payload.listing.data.listing[0].id">
              FIND OUT MORE
            </a>
          </div>
        </div>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import Menu from '@/components/home/Menu.vue';
import Footer from '@/components/home/Footer.vue';
import ContactForm from '@/components/home/ContactForm.vue';

export default {
  components: {
    Menu, Footer, ContactForm
  },
  data() {
    return {
      listing: { 
        data: {
          listing: [
            { on_show: false }
          ]
        }
      },
      agent: { },
      agent_2: { },
      agent_3: { },
      loading: false,
      template: 1,
      activeImageIndex: -1,
      isImageExpanded: false,
      isImageClicked: false,
      originalListings: null,
      listings: null,
      contactRequested: false,
      contactRequested_2: false,
      contactRequested_3: false,
      whatsappLink: '',
      whatsappLink_2: '',
      whatsappLink_3: '',
      current: '',
      next: '',
      prev: '',
      navigationButtons: [],
      ogListing: ''
    };
  },
  created() {
    this.fetchListing().then(() => {
      document.getElementById("ogImage").setAttribute("content", this.listing.featured_image);
      document.getElementById("ogTitle").setAttribute("content", this.listing.data.listing[0].marketing_heading);
    });
    this.fetchListings()
    this.navigationButtons = JSON.parse(localStorage.getItem('setNavigationButtons'))
    this.current = this.$route.params.propertyId
  },
  mounted() {
    this.incrementViews()
  },
  computed: {
    setOGTags() {
      const existingOGTags = document.querySelectorAll('meta[property^="og:"]');
      existingOGTags.forEach(tag => tag.remove());
      const ogTags = [
        { property: 'og:title', content: this.listing.data.listing[0].marketing_heading },
        { property: 'og:description', content: this.listing.ai_description },
        // { property: 'og:image:secure_url', content: this.listing.featured_image },
        { property: 'og:url', content: this.currentUrl },
      ];
      ogTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', tag.property);
        metaTag.setAttribute('content', tag.content);
        document.head.appendChild(metaTag);
      });
    },
    formattedLandSize() {
      const landSize = this.listing && this.listing.data && this.listing.data.listing ? this.listing.data.listing[0].land_size : 0;
      const squareMeters = Math.round(landSize * 10.764);
      return null;
    },
    filteredTags() {
      return Object.fromEntries(
        Object.entries(this.listing.tags || {}).filter(([key, value]) => value === true)
      );
    },
    formattedPrice() {
      const price = this.ogListing[0].price;
      return price.toLocaleString() + " per month";
    },
    formattedDescription() {
      const rawDescription = this.listing.ai_long_description;
      const sentences = rawDescription.split('. ');
      const paragraphs = [];
      for (let i = 0; i < sentences.length; i += 3) {
        const paragraph = sentences.slice(i, i + 3).join('. ');
        if (!paragraph.endsWith('.')) {
          paragraphs.push(paragraph + '.');
        } else {
          paragraphs.push(paragraph);
        }
      }
      return paragraphs;
    },
    currentUrl() {
      return encodeURIComponent(window.location.href)
    },
    facebookShareLink() {
      const text = encodeURIComponent(this.listing.data.listing[0].marketing_heading);
      return `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}`
    },
    linkedinShareLink() {
      const text = encodeURIComponent(this.listing.data.listing[0].marketing_heading);
      return `http://www.linkedin.com/shareArticle?mini=true&url=${this.currentUrl}&title=${text}&source=luxury.chaseveritt.co.za`
    },
    pinterestShareLink() {
      // console.log(this.listing.data.listing[0])
      const text = encodeURIComponent(this.listing.data.listing[0].marketing_heading);
      return `http://pinterest.com/pin/create/bookmarklet/?media=${this.listing.featured_image}&url=${this.currentUrl}]&is_video=false&description=${text}`
    },
    twitterShareLink() {
      const text = encodeURIComponent("Luxury property presented by Chas Everitt Luxury");
      return `https://twitter.com/intent/tweet?url=${this.currentUrl}&text=${text}`;
    },
  },
  methods: {
    incrementLeads: async function () {
      try {
        const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/capture_lead.php', {
          ref_id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        console.log("Leads updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating leads:", error);
      }
    },
    incrementViews: async function () {
      try {
        const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/capture_view.php', {
          ref_id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        console.log("Leads updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating leads:", error);
      }
    },
    fetchListings: async function () {
      this.loading = true;
      try {
        const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.originalListings = response.data
        this.listings = this.originalListings
      } catch (error) {
        console.log(error)
      }
      this.listings = this.listings.filter(listing => {
        return listing.payload && listing.payload.listing && listing.payload.listing.exclusion === '';
      });
      
      this.loading = false
    },
    printToPDF() {
      window.print();
    },
    fetchListing: async function () {
      this.loading = true
      this.$store.state.agentMails = []
      try {
        const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/rentals/listing.php', {
          id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.ogListing = response.data.data.listing
      } catch (error) {
        console.log(error)
      }
      console.log(this.ogListing)
      try {
        const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/rentals/fetch_listing.php', {
          id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.listing = response.data.listing
      } catch (error) {
        console.log(error)
      }
      try {
        const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
          id: this.ogListing[0].agent
        }, {
          headers: {
            'Accept': 'application/json',
          }
        })
        this.agent = agentResponse.data.data.agent[0]
        this.$store.state.agentMails.push(this.agent.email_address)
      } catch (error) {
        console.log(error)
      }
      if (this.ogListing[0].agent_2 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.ogListing[0].agent_2
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_2 = agentResponse.data.data.agent[0]
          this.$store.state.agentMails.push(this.agent_2.email_address)
        } catch (error) {
          console.log(error)
        }
      }
      if (this.ogListing[0].agent_3 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.ogListing[0].agent_3
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_3 = agentResponse.data.data.agent[0]
          this.$store.state.agentMails.push(this.agent_3.email_address)
        } catch (error) {
          console.log(error)
        }
      }

      // Get the current URL
      let currentURL = window.location.href;
      let cleanedNumber = this.agent.cell_number.replace(/\D/g, '');
      let countryCode = '27';
      let formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
      this.whatsappLink = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;

      if (this.agent_2.cell_number) {
        cleanedNumber = this.agent_2.cell_number.replace(/\D/g, '');
        formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
        this.whatsappLink_2 = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;
      }

      if (this.agent_3.cell_number) {
        cleanedNumber = this.agent_3.cell_number.replace(/\D/g, '');
        formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
        this.whatsappLink_3 = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;
      }
      // assign a template to any that don't have
      if (!this.listing.template) {
        this.listing.template = 6
      }
      // assign dynamic values from the Lux API to the current property.
      this.loading = false
    },
    expandImage(index) {
      this.activeImageIndex = index
      this.isImageExpanded = true
      this.isImageClicked = true
    }
  }
};
</script>

<style scoped>
.force-height {
  height: 300px;
  object-fit: cover;
}
  .expanded {
    transform: scale(1.2);
    z-index: 1;
  }
  .social-links{
  position: unset;
  display: inline;
  float: right;
}
.fixed-tags {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
}
.fixed-enquire {
  position: fixed;
  top: 20%;
  right: 20px;
  z-index: 1000;
}
iframe {
  border-bottom-right-radius: 30px;
}
</style>